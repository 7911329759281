import type { Language } from "./Language";
import type { LanguageCollection } from "./LanguageCollection";
import { Service, type ServiceJson } from "./Service";
import { Voice, type VoiceJson } from "./Voice";

export interface SpeechServiceJson extends ServiceJson {
  shortEngineName: string;
  voices: Record<string, VoiceJson>;
  defaultVoices: Record<string, string>;
}

export class SpeechService extends Service {
  shortEngineName: string;
  voices: Record<string, Voice>;
  defaultVoices: Record<string, Voice>;

  constructor(json: SpeechServiceJson, allLanguages: LanguageCollection) {
    super(json, allLanguages);
    this.voices = {};
    for (const [voiceName, voiceJson] of Object.entries(json.voices || {})) {
      this.voices[voiceName] = new Voice(voiceJson, allLanguages, this);
    }
    this.defaultVoices = {};
    for (const [languageTag, voiceName] of Object.entries(
      json.defaultVoices || {}
    )) {
      this.defaultVoices[languageTag] = this.voices[voiceName]!;
    }
    this.shortEngineName = json.shortEngineName;
  }

  getVoiceByName(localName: string): Voice | undefined {
    if (this.voices) {
      return this.voices[localName];
    }
    return undefined;
  }

  getDefaultVoiceForLanguage(language?: Language): Voice | undefined {
    if (language) {
      return this.defaultVoices[language.toString()];
    }
    return undefined;
  }
}
